import { ClyCoreConfig } from '@coinlocally/cly-core';
import MainLayout from 'common/components/layout/main-layout';
import Loader from 'common/components/loader';
import { Suspense, lazy, useEffect, useRef, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { BrowserRouter, Outlet, Route, Routes, useNavigate, useParams } from 'react-router-dom';

const FuturesDesktop = lazy(() => import('pages/futures/futures-desktop.view'));
const FuturesMobile = lazy(() => import('pages/futures/futures-mobile.view'));

const App = () => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [workers, setWorkers] = useState({
		orderBook: './workers/futures/orderBookV4.js',
		price: './workers/futures/pair-price-updater.js',
		private: './workers/futures/socket-private-v3.js',
		public: './workers/futures/socket-public-v2.js',
	});

	const PublicSocketWorker = useRef<Worker>(new Worker(workers.public));
	const SharedOrderBookWorker = useRef<Worker>(new Worker(workers.orderBook, { type: 'module' }));
	const PairPriceUpdaterWorker = useRef<Worker>(new Worker(workers.price));
	const SocketPrivateWorker = useRef<Worker>(new Worker(workers.private));

	useEffect(() => {
		// TODO: fix dynamic url serve workers
		// TODO!: DO not remove below comments
		// fetch('./workers/futures/socket-private-new.js')
		// 	.then(response => response.blob())
		// 	.then(blob => {
		// 		setWorkers(prev => ({ ...prev, private: URL.createObjectURL(blob) }));
		// 	});
		// fetch('./workers/futures/orderBookV3.js')
		// 	.then(response => response.blob())
		// 	.then(blob => {
		// 		setWorkers(prev => ({ ...prev, orderBook: URL.createObjectURL(blob) }));
		// 	});
		// fetch('./workers/futures/pair-price-updater.js')
		// 	.then(response => response.blob())
		// 	.then(blob => {
		// 		setWorkers(prev => ({ ...prev, price: URL.createObjectURL(blob) }));
		// 	});
		// fetch('./workers/futures/socket-public.js')
		// 	.then(response => response.blob())
		// 	.then(blob => {
		// 		setWorkers(prev => ({ ...prev, public: URL.createObjectURL(blob) }));
		// 	});

		return () => {
			PublicSocketWorker.current.terminate();
			SharedOrderBookWorker.current.terminate();
			PairPriceUpdaterWorker.current.terminate();
			SocketPrivateWorker.current.terminate();
		};
	}, []);

	const LanguageDetector = () => {
		const { lng } = useParams();
		const { language, changeLanguage } = useTranslation().i18n;

		const languages = [
			'en',
			'fa',
			'ar',
			// 'de', 'es',
			'ru',
		];

		useEffect(() => {
			if (lng && language !== lng && languages.includes(lng)) {
				changeLanguage(lng);
				ClyCoreConfig.LANGUAGE = lng as
					| 'en'
					| 'fa'
					| 'ar'
					// 'de'
					| 'ru';
			}
		}, [lng, language]);

		return <Outlet />;
	};

	return (
		<MainLayout>
			<BrowserRouter>
				<Suspense fallback={<Loader />}>
					<Toaster
						position='bottom-right'
						containerStyle={{
							zIndex: 9999999999,
						}}
					/>

					<Routes>
						<Route path=':lng' element={<LanguageDetector />}>
							<Route path='futures' element={<Redirect path='BTC_USDT' />} />
							<Route
								path='futures/:pair'
								element={
									// workers.orderBook !== '' && workers.price !== '' && workers.public !== '' && workers.private !== '' ? (
									window.screen.width > 600 && window.innerWidth > 600 ? (
										<FuturesDesktop
											workers={{
												orderBook: SharedOrderBookWorker.current,
												price: PairPriceUpdaterWorker.current,
												private: SocketPrivateWorker.current,
												public: PublicSocketWorker.current,
											}}
										/>
									) : (
										<FuturesMobile
											workers={{
												orderBook: SharedOrderBookWorker.current,
												price: PairPriceUpdaterWorker.current,
												private: SocketPrivateWorker.current,
												public: PublicSocketWorker.current,
											}}
										/>
									)
								}
							/>
						</Route>
					</Routes>
				</Suspense>
			</BrowserRouter>
		</MainLayout>
	);
};

const Redirect = (props: { path: string }) => {
	const navigate = useNavigate();
	useEffect(() => {
		navigate(props.path);
		// eslint-disable-next-line
	}, []);

	return null;
};

export default App;
